import { Environment } from '@app/shared/model/environment';
import { environment as environmentDev } from './environment.aws-development';

export const environment: Environment = {
  ...environmentDev,
  apiClientMaxConcurrency: 10,
  production: true,
  envName: 'PROD',
  keycloakServiceUrl: 'https://kingfisher.vwapps.run/keycloak',
  keycloakLogoutRedirectUri: 'https://kingfisher.vwapps.run/',
  keycloakGrafanaLogoutRedirectUri:
    'https://kingfisher.vwapps.run/grafana/logout',
  ndsMapViewerUrl: 'https://mapviewer.kingfisher.vwapps.run/'
};
